@use "../config/" as *;

.athletes-ranking {
    padding: 0 var(--half-space);
    @extend %m-zero;

    .waf-head {
        margin-block: var(--two-space) var(--full-space);
        display: none;
    }

    .title {
        @extend %font-41-sb;
        @extend %uppercase;
    }

    .head-wrap {
        @extend %d-block;
        @extend %relative;
    }

    .head-tab {
        @extend %d-none;
    }

    .table {
        &-group {
            @extend %m-x-full-neg;
            .loadmore { 
                width: 100%;
            }
        }

        &-wrapper {
            margin-bottom: 4.2rem;

            &>.table-head {
                background-color: hsl(var(--hsl-c-blue-light)/0.1);
                height: auto;
                border-block: 0.1rem solid var(--c-light-grey-1);
                @extend %flex-sb-c;
                @extend %p-full;

                .title {
                    @extend %font-20-pb;
                    @extend %c-blue-10;
                }

                a {
                    @extend %font-14-pb;
                }
            }
        }

        &>.table-head {
            @extend %d-none;
        }

        &-row {
            padding: var(--full-space) var(--half-space);
        }

        .fullname {
            @extend %d-none;
        }

        .image {
            width: 4rem;
            height: 4rem;
            flex-shrink: 0;
            @extend %ratio-1-1;
            @extend %circle-radius;
            @extend %c-blue-1-bg-10;
        }

        &-info {
            gap: var(--full-space);
            flex-direction: row-reverse;
            @extend %flex-n-c;
        }
    }

    .rank {
        width: 4rem;

        .text {
            @extend %c-blue-8;
            @extend %font-12-pr;
        }
    }

    .shortname {
        @extend %text-left;

        .name {
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            display: inline-block;
        }
    }

    .fname {
        @extend %c-blue-6;
    }

    .logo {
        width: 1.6rem;
        height: 1.6rem;
    }

    .country {
        align-self: flex-start;
        flex-direction: row-reverse;
        gap: 0.6rem;
        width: max-content;
        @extend %flex-fe-n;

        .text {
            @extend %c-blue-6;
            @extend %font-12-pr;
            @extend %uppercase;
        }
    }

    .pts {
        justify-content: flex-end;
        min-width: 10rem;

        .text {
            @extend %flex;
            @extend %font-12-pr;
            @extend %c-blue-8;
        }

        span {
            padding-left: 0.5rem;
            @extend %c-blue-8;
        }
    }

    .up {
        .text {
            &:before {
                content: '\e802';
                @extend %c-success-10;
            }
        }
    }

    .up,
    .down {
        .text {
            &:before {
                font: 2rem $font-icon;
                @extend %m-r-half;
            }
        }
    }

    .down {
        .text {
            &:before {
                content: '\e806';
                @extend %c-orange-10;
            }
        }
    }

    .player {
        min-width: 16rem;

        .shortname {
            flex-direction: row;
            @extend %flex;
        }

        .fullname {
            @extend %d-none;
        }

        .table-info {
            flex-direction: column;
            row-gap: 0;
            margin-left: 1.2rem;
        }
    }

    .highlight {
        @extend %c-blue-light-bg-3;

        .table-info {
            gap: .5rem;
        }

        .image {
            width: 5rem;
            height: 100%;
            border-radius: unset;
            background: transparent;
        }

        .rank {
            .text {
                font-family: $font-pb;
            }
        }
    }

    .card-action {
        position: relative;

        &:hover {
            .card-text {
                @extend %flex;
            }
        }

        button {
            width: 2.6rem;
            height: 2.6rem;
            @include border-radius(50%);
            @extend %c-orange-bg-10;
        }

        .btn-text {
            @extend %font-zero;

            &:after {
                content: "\e824";
                font: 2rem $font-icon;
                @extend %c-white-10;
            }
        }
    }

    .card-text {
        width: max-content;
        border-radius: 0.3rem;
        gap: 1rem;
        padding-inline: 0.4rem;
        position: absolute;
        bottom: -2rem;
        left: -3rem;
        @extend %d-none;
        @extend %c-blue-bg-6;
        @extend %c-white-10;
        @extend %font-12-pr;
    }

    .team {
        min-width: 15rem;
    }

    .filter-section {
        padding: 0;

        .label-group-head,
        .filter-hide-data,
        .filter-search {
            @extend %d-none;
        }
    }

    @extend %white-bg;
    // :not(.detail-container-wrapper) {
    //     .image {
    //         @include position-combo("y-center", null, 3.3rem);
    //     }
    //     .highlight .image {
    //         width: 5.1rem;
    //         height: 6rem;
    //         top: unset;
    //         bottom: 0;
    //         object-fit: contain;
    //         transform: unset;
    //     }
    //     .team {
    //         max-width: calc(50% - 7.6rem);
    //         min-width: unset;
    //         flex-basis: calc(50% - 7.6rem);
    //         margin-left: 5.4rem;
    //     }
    //     .table-info,
    //     .fullname,
    //     .shortname,
    //     .fname,
    //     .lname,
    //     .name {
    //         width: 100%;
    //     }
    //     .name {
    //         display: inline-block;
    //         overflow: hidden;
    //         text-align: left;
    //         text-overflow: ellipsis;
    //         white-space: nowrap;
    //     }
    //     .rank {
    //         width: unset;
    //         flex-basis: 2.5rem;
    //     }
    //     .country {
    //         flex-basis: 20%;
    //     }
    //     .pts {
    //         min-width: unset;
    //         flex-basis: 30%;
    //     }
    // }
    .loadmore {
        width: 100%;
        @extend %m-y-half;
        @extend %flex-c-n;
        button {
            @extend %p-x-full;
            @extend %p-y-half;
            @extend %flex-c-c;
            @extend %c-blue-bg-10;
            @include border-radius(var(--two-space));
            .btn-text {
                @extend %c-white-10;
                @extend %uppercase;
                @extend %font-14-pb;
            }
            &:hover {
                @extend %c-orange-bg-10;
            }
        }
    }
}

.athletes-results-page {
    .athletes-ranking {
        .waf-head {
            margin-block: 0;
        }
    }
}

.featured-item {
    position: relative;
    padding: 2.4rem var(--half-space);
    @extend %m-b-two-space;

    .meta {
        font: 1.8rem $font-psb;
        color: var(--c-white);
        @extend %capitalize;

        &:not(:nth-child(2)) {
            &::before {
                content: unset;
            }
        }

        &:nth-child(2) {
            gap: var(--quarter-space);
            @extend %flex;

            &::after {
                content: "|";
            }
        }

        &-title {
            gap: var(--full-space);
            margin-bottom: 2.8rem;
            @extend %m-zero;
            @extend %flex-c-c;
        }
    }

    .card {
        &-item {
            position: relative;
            @extend %m-b-two-space;
            @extend %p-half;
            @extend %flex;

            .name {
                @extend %font-14-pr;
            }

            .fname {
                @extend %c-white-6;
            }
        }

        &-number {
            width: 3.6rem;
            height: 3.6rem;
            flex-shrink: 0;
            position: absolute;
            top: -1.7rem;
            border: 0.1rem solid hsl(var(--hsl-c-white)/0.6);
            z-index: var(--zindex1);
            @extend %ratio-1-1;
            @extend %circle-radius;
            @extend %flex-c-c;
            @extend %c-blue-2-bg-8;
            @extend %font-20-pb;
            @extend %c-white-10;
        }

        &-content {
            flex-wrap: wrap;
            flex-direction: row;
            @extend %flex;
            @extend %m-l-half;
        }

        &-thumbnail {
            flex-basis: 50%;

            img {
                width: auto;
                border-radius: unset;
            }
        }

        &-name {
            flex-basis: 100%;
            @extend %m-zero;
            @extend %flex-column-fs-fs;

            .lname {
                @extend %c-white-10;
            }
        }

        &-data {
            flex-basis: 100%;
            gap: 0.6rem;
            flex-direction: row-reverse;
            @extend %flex-fe-c;

            .logo {
                width: 1.5rem;
                height: 1.5rem;
                @extend %ratio-1-1;
            }

            .text {
                @extend %c-white-8;
            }
        }

        &-points {
            flex-basis: 70%;
            order: 1;
            font-family: $font-psb;
            @extend %c-orange-10;
            @extend %flex;
        }

        &-action {
            flex-basis: 30%;
            order: 1;
            @extend %flex-fe-c;

            button {
                @extend %c-white-bg-10;
            }

            .btn-text {
                &::after {
                    color: var(--c-blue); //overriding @extend value
                }
            }
        }

        &-text {
            @extend %c-white-bg-4;
        }
    }
}

.detail-container-wrapper {
    .table {
        border: 0;

        &-body {
            .table-row {
                position: relative;

                &:nth-child(odd) {
                    &::before {
                        content: '';
                        pointer-events: none;
                        @include linear-gradient(104.13deg, hsla(var(--hsl-c-light-grey-1)/0.2), hsla(var(--hsl-c-light-grey-1)/ 0.2));
                        @extend %position-t-l;
                        @extend %w-100;
                        @extend %h-100;
                    }
                }
            }
            .table-data.player {
                min-width: 20rem;
            }
        }
    }
}

@media (min-width: $tablet-min-width) {
    .athletes-ranking .filter-section {
        margin-block: var(--half-space) 6.8rem;
        .filter {
            &-wrap {
                .filter-body {
                    width: 100%;
                }
                .sub-title {
                    color: hsl(var(--hsl-c-black)/.6);
                }
                .selected-label {
                    border-color: hsl(var(--hsl-c-black)/.2);
                    color: var(--c-black);
                }
            }
            &-body {
                z-index: unset;
                align-items: flex-end;
                gap: var(--full-space);
            }
            &-group {
                flex-basis: unset;
                gap: var(--full-space);
                .waf-select-box {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
            &-label-group {
                margin-block: 0;
                flex-basis: unset;
                flex-grow: 1;
            }
        }
        .tabs {
            margin-block: 0;
        }
        .tab-name {
            min-width: 6.4rem;
            height: 3rem;
            padding: 0;
            @include flex-config(flex, null, center, center);
        }
    }

    .detail-container-wrapper {
        .table-body {
            .table-row {
                &:hover {
                    box-shadow: 0 .4rem 1.6rem 0 hsla(var(--hsl-c-black)/0.1);
                }
            }
        }
    }

    .waf-standings {
        .featured-item {
            height: 55rem;
        }
    }
}

@media (min-width:$desktop-min-width) {
    .athletes-ranking {
        padding-top: var(--one-n-half-space);
        max-width: var(--container-max-width);
        margin: auto;
        @include white-bg();

        .layout-wrapper {
            max-width: 100%;
            margin: 0;
        }

        .waf-head {
            margin-top: 8rem;
        }

        .head-wrap {
            display: flex;
        }

        .title {
            font-size: 6.4rem;
        }

        .table {
            border: none;

            &-group {
                margin-inline: 0;
                @include card-count(2, var(--three-space));
            }

            &-wrapper {
                border-radius: 0.4rem;
                border: 0.1rem solid hsl(var(--hsl-c-black)/0.05);

                &>.table-head {
                    border-block: unset;
                }
            }

            &-data {
                &.team {
                    min-width: 20rem;
                }
            }
            &-row {
                padding: var(--full-space) 3rem var(--full-space) 2rem;
            }

            .fullname {
                @include flex-config(flex, column, flex-start, flex-start);
            }

            .shortname {
                display: none;
            }
        }

        .fname {
            font-size: 1.4rem;
            text-align: left;
        }

        .lname {
            font-size: 1.6rem;
            text-align: left;
        }

        .logo {
            width: 2.4rem;
            height: 2.4rem;
        }

        .player {
            .table-info {
                flex-direction: row;
            }

            .fullname {
                min-width: 30rem;
                @include flex-config(flex, column);
            }

            .shortname {
                display: none;
            }

            .country {
                min-width: 20rem;
                align-self: center;

                .text {
                    text-transform: capitalize;
                    font-size: 1.8rem;
                }
            }
        }

        .compare {
            justify-content: flex-end;
        }

        .pts {
            .text {
                font-size: 1.8rem;
            }
        }

        .image {
            width: 4.6rem;
            height: 4.6rem;
        }

        .highlight {
            .image {
                width: 7.4rem;
                margin-bottom: var(--full-space-negative);
            }
        }

        .card-action {
            button {
                width: 3.2rem;
                height: 3.2rem;
            }
        }

        .filter-section {
            input {
                border-bottom: none;
            }
        }

        .table-data {
            &.rank {
                .text {
                    font-size: 1.6rem;
                }
            }

            &.pts,
            &.country {
                .text {
                    font-size: 1.4rem;
                }
            }
        }

        .table-group {
            .table-head {
                padding-right: 3.4rem;
            }
        }

        .detail-container-wrapper {
            .rank {
                .text {
                    @include font(24, $font-pb);
                }
            }

            .fname {
                font-size: 1.6rem;
            }

            .lname {
                font-size: 1.8rem;
            }

            .card-item:nth-child(2) {
                .fname {
                    font-size: 1.8rem;
                }
                .lname {
                    font-size: 2rem;
                }
            }
        }

        .filter-section {
            .secondary.tabs {
                width: 100%;
            }

            .filter-group {
                .waf-select-box {
                    max-width: 15rem;
                }
            }
        }

        .featured-item {
            .card-content {
                justify-content: space-between;
            }
        }

        // :not(.detail-container-wrapper) {
        //     .image {
        //         left: 5rem;
        //     }
        //     .highlight .image {
        //         width: 6.3rem;
        //         height: 7.4rem;
        //     }
        //     .team {
        //         max-width: calc(50% - 9.3rem);
        //         flex-basis: calc(50% - 9.3rem);
        //         margin-left: 6rem;
        //     }
        //     .rank {
        //         flex-basis: 4rem;
        //     }
        //     .country {
        //         flex-basis: 30%;
        //     }
        //     .pts {
        //         flex-basis: 20%;
        //     }
        // }
    }
    .detail-container-wrapper {
        .table-wrapper {
            border: 0;
        }

        .compare {
            width: 8rem;
        }

        .pts {
            width: 20rem;
        }

        .player {
            padding-left: calc(4*var(--full-space));

            .image {
                width: 4.6rem;
                height: 4.6rem;
            }
        }
    }

    .featured-item {
        padding: 5.3rem 0 8.1rem;

        .meta {
            font-size: 2.4rem;

            &-title {
                padding-top: 4rem;
                margin-bottom: 5.3rem;
            }
        }

        .card {
            &-item {
                flex-direction: column;
                justify-content: center;
                position: relative;
                margin-bottom: 0;

                &:first-child {
                    .card-number {
                        width: 6.1rem;
                        height: 6.1rem;
                        font-size: 3rem;
                    }
                }

                &:last-child {
                    .card-number {
                        width: 4.8rem;
                        height: 4.8rem;
                        font-size: 2rem;
                    }
                }
            }

            &-content {
                flex-basis: 100%;
                justify-content: space-between;
                padding: var(--full-space);
                margin-left: 0;
                @include bg(c-blue, 10);
            }

            &-number {
                width: 7.6rem;
                height: 7.6rem;
                top: unset;
                bottom: calc(60% + 3rem);
                right: 2rem;
                font-size: 4rem;
            }

            &-points {
                order: unset;
                flex-basis: 30%;
                justify-content: flex-end;
            }

            &-name {
                flex-basis: 70%;

                .fname {
                    line-height: 2rem;
                }
            }

            &-data {
                flex-basis: 50%;
                order: unset;
            }

            &-action {
                flex-basis: unset;
            }
        }
    }
}