@use "../config/" as *;
.waf-podium,
.waf-standings .featured-item {
    padding: 0 var(--half-space);
    isolation: isolate;
    @extend %half-radius;
    @extend %c-blue-bg-10;
    @extend %c-white-10;
    &::before {
        pointer-events: none;
        z-index: var(--zindex-pattern);
        background: url('/static-assets/images/events/event-banner-bg.png?v=#{$image-version}') center/ cover no-repeat;
        @extend %full-radius;
        @extend %h-100;
        @extend %w-100;
        @extend %position-t-l;
    }
    &.olympic-qualifying-tournament,
    &.olympic-games {
        &::before {
            background-image: url("/static-assets/images/events/olympic-qualifier-bg.png?v=#{$image-version}");
        }
    }
    @each $event-name,
    $event-id in $events {
        &.#{$event-name} {
            @include linear-gradient(0deg,
                hsl(var(--hsl-c-#{$event-id}-secondary)) 0,
                hsl(var(--hsl-c-#{$event-id}-secondary)/0.9) 100%);
        }
    }
    // Add light bg styles
    &.olympic-qualifying-tournament,
    &.olympic-games {
        .title {
            @extend %c-black-10;
        }
        .article {
            &-item {
                border-color: transparent;
            }
            &-wrap {
                @extend %c-black-bg-3;
            }
            &-thumbnail::after,
            &-content {
                border-color: hsl(var(--hsl-c-black)/.2);
            }
        }
        .tabs .tab-text {
            @extend %c-black-6;
        }
        .tabs .tab-name.active {
            border-color: var(--c-black);
            .tab-text {
                @extend %c-black-10;
            }
        }
        .meta-value,
        .first-name {
            @extend %c-black-6;
        }
        .last-name,
        .btn-more .btn-text {
            @extend %c-black-10;
        }
        .selected-label,
        .selected-label::after {
            @extend %c-black-4;
        }
        .waf-select-box:last-child::before {
            @extend %c-black-bg-4;
        }
    }
    .waf-head {
        @extend %p-t-two-space;
        @extend %m-b-two-space;
    }
    .title,
    .article-main-title {
        @extend %font-42-sb;
        @extend %c-white-10;
        @extend %uppercase;
        @extend %text-center;
    }
    .waf-body {
        @extend %p-b-two-space;
    }
    .waf-footer {
        border-top: 0.1rem solid hsl(var(--hsl-c-white)/0.6);
        @extend %flex-c-c;
        @extend %p-y-half;
        @extend %m-x-half-neg;
        .text {
            @extend %font-zero;
            &:after {
                @include icon('\e816');
                @include color(c-white);
            }
        }
        .social-share-wrap {
            @extend %position-h-center;
        }
    }
    .article,
    .card {
        &-list {
            gap: var(--two-space);
            @extend %flex-column;
        }
        &-item {
            height: 11.2rem;
            @extend %c-white-bg-2;
            @include border-radius(var(--quarter-space));
            @include border(1, c-white, 2);
        }
        &-wrap {
            justify-content: flex-end;
            @extend %h-100;
            @extend %flex;
            @extend %relative;
        }
        &-content {
            width: 40%;
            flex-direction: column;
            @extend %flex-c-n;
            @extend %p-y-half;
        }
        &-content-wrap:not(:first-child) {
            margin-top: .4rem;
        }
        &-thumbnail {
            width: 40%;
            height: 12rem;
            @include position(null, null, 0rem, 4.2rem);
            @extend %flex;
            .img-box {
                filter: drop-shadow(0 0rem 3rem hsl(var(--hsl-c-white)/0.6));
                @extend %h-100;
            }
            img {
                // object-fit: cover;
                @extend %h-100;
            }
        }
        &-name {
            line-height: 1;
        }
        &-meta {
            margin-top: .2rem;
            gap: var(--quarter-space);
            @extend %flex-n-c;
        }
    }
    .meta {
        &-img {
            width: 1.6rem;
            aspect-ratio: 1;
            flex-shrink: 0;
            object-fit: cover;
            @extend %circle-radius;
        }
        &-value {
            width: calc(100% - 1.6rem - var(--quarter-space));
            @include truncate-text(1);
            @extend %c-white-6;
            @extend %font-12;
        }
    }
    .article-main-title {
        margin-block: 0 var(--one-n-half-space);
    }
    .medal-section {
        @include position(.5rem, null, null, 0);
        @extend %flex-c-fs;
    }
    .medal-image {
        width: 4rem;
        height: 4.5rem;
        object-fit: contain;
    }
    .name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @extend %d-block;
        @extend %capitalize;
        @extend %font-16-pr;
        @extend %c-white-10;
    }
    .first-name {
        color: hsl(var(--hsl-c-white) / .6);
    }
    .last-name {
        color: var(--c-white);
        -webkit-line-clamp: 2;
        @extend %d-block;
    }
    .two-position {
        min-height: 11.2rem;
        height: auto;
        .article {
            &-thumbnail {
                width: 55%;
                left: var(--full-space);
            }
            &-content {
                padding-block: var(--half-space);
            }
        }
        .name {
            @extend %font-12-pr;
        }
        .last-name {
            -webkit-line-clamp: 1;
        }
    }
    .tabs-section {
        width: max-content;
        @extend %text-center;
        @extend %m-auto;
        .tab {
            &-text {
                @extend %c-white-6;
            }
            &-name {
                border-color: transparent;
                &.active {
                    .tab-text {
                        @extend %c-white-10;
                    }
                    border-color: var(--c-light-grey-1);
                }
            }
        }
    }
    .meta {
        @extend %font-24-sb;
        @extend %c-white-4;
        &::before {
            content: "|";
        }
    }
}
.waf-podium {
    padding: 5rem var(--half-space) 2rem;
    border-radius: unset;
    margin: 0;
    background: unset;
    @extend %h-100;
    @each $event-name,
    $event-id in $events {
        &.#{$event-name} {
            background: unset;
            .logo-wrap {
                background: url('/static-assets/images/cssimages/svg/events/#{$event-name}.svg') center / contain no-repeat;
            }
            @if $event-name =="olympic-qualifying-tournament" or $event-name =="olympic-games" {
                .logo-wrap {
                    background: url('/static-assets/images/cssimages/svg/events/olympic-qualifying-tournament-logo.svg') center / contain no-repeat;
                }
            }
        }
        &.#{$event-name} .layout-wrapper {
            @include linear-gradient(0deg,
                hsl(var(--hsl-c-#{$event-id}-secondary)) 0,
                hsl(var(--hsl-c-#{$event-id}-secondary)/0.9) 100%);
        }
    }
    &::before {
        content: unset;
    }
    &.world-cup {
        .article {
            &-item {
                height: auto;
                display: flex;
            }
            &-wrap {
                height: auto;
                display: grid;
                gap: 1rem;
                grid-template-columns: 4rem 9rem 1fr;
            }
            &-thumbnail {
                height: auto;
                margin-top: 0;
                margin-left: 0;
                justify-content: center;
                .img-box {
                    width: 100%;
                    filter: drop-shadow(0 -1rem 2rem hsl(var(--hsl-c-blue-light) / .6));
                    @extend %flex-c-c;
                }
                img {
                    width: 7.7rem;
                    height: 7.7rem;
                    object-fit: cover;
                    @extend %circle-radius;
                }
            }
            &-content-wrap:not(:first-child) {
                margin-top: var(--full-space);
            }
        }
        .name {
            font-size: 1.8rem;
        }
        .two-position .article-thumbnail {
            flex-direction: column;
            gap: var(--half-space);
            justify-content: center;
            img {
                width: 4.1rem;
                height: 4.1rem;
            }
            :nth-child(2) {
                margin-left: 0;
            }
        }
    }
    &.olympic-qualifying-tournament,
    &.olympic-games {
        .layout-wrapper {
            background-color: var(--c-olympic-qualifier-primary);
            &::before {
                background-image: url('/static-assets/images/events/event-banner-bg-dark.png?v=#{$image-version}');
                filter: invert(1);
            }
        }
    }
    &:has(.logo-wrap) .layout-wrapper {
        padding-top: var(--three-space);
    }
    .layout-wrapper {
        padding: var(--two-space) 1.2rem;
        isolation: isolate;
        @extend %half-radius;
        @extend %relative;
        @extend %c-blue-bg-10;
        &::before {
            content: "";
            z-index: var(--zindex-pattern);
            background: url('/static-assets/images/events/event-banner-bg.png?v=#{$image-version}') center/ cover no-repeat;
            pointer-events: none;
            @extend %full-radius;
            @extend %h-100;
            @extend %w-100;
            @extend %position-t-l;
        }
    }
    .waf-head {
        padding-top: 0;
        @extend %m-b-zero;
    }
    .title {
        margin-bottom: var(--full-space);
        font-size: 2.6rem;
        line-height: 3rem;
    }
    .tabs {
        margin-block: 0 var(--full-space);
        gap: var(--one-n-half-space);
        .tab {
            &-name {
                padding: 0;
            }
            &-text {
                font-size: 1.4rem;
            }
        }
    }
    .logo {
        &-wrap {
            width: 5.8rem;
            aspect-ratio: 1;
            transform: translate(-50%, -50%);
            @include position(0, null, null, 50%);
        }
        &-img {
            display: none;
        }
    }
    .dropdown-wrap {
        gap: var(--three-space);
        @extend %flex-c-c;
    }
    .select-box-wrap {
        width: 100%;
        height: 0;
        border-radius: var(--half-radius);
        z-index: var(--zindex-dropdown);
        background-color: var(--c-white);
        overflow: hidden;
        transition: height .3s ease-in;
        @include position(3.2rem, null, null, 0);
    }
    .select-list {
        width: 100%;
        height: 100%;
        box-shadow: 0 .4rem 1.6rem hsl(var(--hsl-c-black)/0.1);
        overflow-y: auto;
        @include custom-scroll();
    }
    .list-item {
        padding: var(--half-space) 1.2rem;
        border-bottom: .1rem solid var(--c-light-grey-1);
        text-align: left;
        @extend %w-100;
        @extend %d-block;
        @extend %c-blue-10;
    }
    .waf-select-box {
        @extend %relative;
        &:last-child::before {
            content: "";
            width: .2rem;
            height: 2.4rem;
            pointer-events: none;
            @include position-combo("y-center", null, -2.5rem);
            @extend %c-white-bg-4;
        }
        &.active {
            .select-box-wrap {
                height: 15rem;
            }
            .selected-label::after {
                transform: rotate(180deg);
            }
        }
    }
    .selected-label {
        gap: 1.2rem;
        line-height: 1;
        @extend %c-white-4;
        @extend %font-18-pr;
        @extend %flex-n-c;
        &:hover {
            cursor: pointer;
        }
        &::after {
            content: "\E806";
            font: 2.4rem/1 $font-icon;
            transition: transform .4s ease-in;
            @extend %c-white-6;
        }
    }
    .article {
        &-list {
            margin-top: 5rem;
        }
        &-content {
            @extend %p-x-quarter;
        }
        &-thumbnail img {
            object-fit: cover;
        }
    }
    .article-main-title {
        font-size: 2.6rem;
    }
    .meta-weight {
        font-size: inherit;
        &::before {
            margin-inline: var(--half-space);
            display: inline-block;
        }
    }
    .two-position {
        .article-thumbnail {
            :nth-child(2) {
                margin-left: -2rem;
            }
        }
    }
    .swiper {
        &-pagination {
            position: static;
            gap: var(--half-space);
            @extend %m-t-one-n-half;
            @extend %d-none;
            // @extend %flex-c-n;
            &-bullet {
                width: 1.6rem;
                height: .2rem;
                @extend %rounded-radius;
                @extend %c-white-bg-2;
                &-active {
                    @extend %c-white-bg-10;
                }
            }
        }
        &-button {
            @extend %d-none;
        }
    }
    .waf-footer {
        padding-block: 0;
        border-top: 0;
    }
    .social-share {
        display: none;
    }
    .btn-more .btn-text {
        line-height: 1;
        @extend %font-16-pb;
    }
}
.waf-standings .featured-item {
    .meta-title {
        padding-block: var(--one-n-half-space) 4rem;
        margin-bottom: 0;
        gap: var(--quarter-space);
        .meta {
            @extend %font-18-psb;
        }
    }
    .card {
        &-item {
            margin-bottom: 0;
            justify-content: flex-end;
        }
        &-thumbnail {
            width: 11.3rem;
            height: 13rem;
            left: 3.5rem;
            .image {
                width: 100%;
                aspect-ratio: unset;
                object-fit: contain;
                object-position: bottom;
            }
        }
        &-content {
            padding-block: 0;
            display: grid;
            align-items: center;
            flex-basis: 50%;
            justify-content: space-between;
            grid-template-areas: 'a a'
                'b b'
                'c d';
        }
        &-number {
            left: 1rem;
        }
        &-name {
            grid-area: a;
            .name {
                font-size: 1.4rem;
                line-height: 1.35;
            }
            .fname {
                @extend %c-white-6;
            }
        }
        &-data {
            grid-area: b;
        }
        &-points {
            grid-area: c;
            line-height: 1;
        }
        &-action {
            grid-area: d;
        }
    }
    .card-thumbnail .image {
        background: transparent;
        filter: drop-shadow(0 -4rem 3rem hsl(var(--hsl-c-white) / .7));
    }
    .meta {
        color: var(--c-white);
    }
}
.homepage .waf-podium .tabs-section {
    width: unset;
}
.waf-component .waf-podium {
    padding-inline: 0;
}
@media screen and (min-width:$tablet-min-width) {
    .waf-podium,
    .waf-standings .featured-item {
        position: relative;
        height: 60rem;
        @include border-radius(2.8rem);
        @each $event-name,
        $event-id in $events {
            &.#{$event-name} {
                .article-content {
                    background-color: var(--c-#{$event-id}-secondary);
                }
            }
        }
        &::before {
            content: '';
        }
        &.olympic-qualifying-tournament,
        &.olympic-games {
            .article {
                &-wrap {
                    background-color: transparent;
                }
                &-thumbnail::after {
                    background-color: hsl(var(--hsl-c-blue-light) / .3);
                }
                &-content {
                    background-color: var(--c-light-grey-2);
                }
            }
        }
        .title,
        .article-main-title {
            font-size: 6rem;
            line-height: 7.2rem;
        }
        .article,
        .card {
            &-list {
                width: 90%;
                margin-inline: auto;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                column-gap: var(--full-space);
                row-gap: 0;
            }
            &-item {
                position: relative;
                height: 37rem;
                background: transparent;
                border: 0;
                grid-row: 2/6;
                &:first-child {
                    grid-row: 3/6;
                    height: 25rem;
                    .article-thumbnail,
                    .card-thumbnail {
                        height: 14rem;
                    }
                }
                &:last-child {
                    grid-row: 4/6;
                    height: 22rem;
                    .article-thumbnail,
                    .card-thumbnail {
                        height: 11rem;
                    }
                }
            }
            &-wrap {
                flex-direction: column;
                justify-content: center;
            }
            &-content {
                width: unset;
                padding: 1rem var(--half-space) var(--full-space);
                min-height: 13rem;
                flex-basis: unset;
                align-items: flex-start;
                margin-left: 0;
                border-color: hsl(var(--hsl-c-white)/0.2);
                border-style: solid;
                border-width: 0 .1rem .1rem .1rem;
                justify-content: flex-start;
                @include border-radius(0 0 var(--half-radius) var(--half-radius));
            }
            &-content-wrap {
                width: 100%;
                &:not(:first-child) {
                    margin-top: 0;
                }
            }
            &-name {
                flex-wrap: wrap;
                @include flex-config(flex, null, center, center);
            }
            &-thumbnail {
                width: unset;
                position: relative;
                height: 24rem;
                margin-top: 0;
                bottom: unset;
                left: unset;
                flex-basis: unset;
                justify-content: center;
                isolation: isolate;
                &::after {
                    content: '';
                    height: 60%;
                    width: calc(100% - .2rem);
                    border-color: hsl(var(--hsl-c-white)/0.2);
                    border-style: solid;
                    border-width: .1rem .1rem 0 .1rem;
                    z-index: var(--zindex-pattern);
                    @include border-radius(var(--half-radius) var(--half-radius) 0 0);
                    @include position(null, null, 0, 0);
                    @include bg(c-white, 2);
                }
                .img-box {
                    filter: drop-shadow(0 -3rem 4rem hsl(var(--hsl-c-white)/0.4));
                }
            }
            &-meta {
                justify-content: center;
            }
        }
        .first-name {
            width: 100%;
            text-align: center;
        }
        .last-name {
            -webkit-line-clamp: 1;
        }
        .medal-section {
            flex-basis: unset;
            @include position(unset, null, -5rem, 50%);
            @include translate(-50%, 0);
            .medal-image {
                width: 8rem;
                height: 9rem;
            }
        }
        .two-position {
            .article {
                &-content {
                    flex-direction: row;
                    margin-left: 0;
                    flex-basis: 30%;
                    flex-grow: unset;
                    gap: var(--half-space);
                }
                &-thumbnail {
                    width: unset;
                    height: 24rem;
                    left: unset;
                    gap: var(--full-space)
                }
            }
            .first-name {
                @include font(13);
            }
            .last-name {
                font-family: $font-psb;
                font-size: 1.4rem;
                text-align: center;
            }
            .meta-value {
                line-height: 1.3;
                -webkit-line-clamp: 2;
            }
        }
        .meta-value {
            max-width: calc(100% - 1.6rem - var(--quarter-space));
            width: auto;
            font-size: 1.4rem;
        }
        .waf-footer {
            border: none;
            @include position(var(--two-space), var(--one-n-half-space));
            .social-share-wrap {
                right: 0;
                left: unset;
                transform: unset;
            }
        }
    }
    .waf-podium {
        height: 100%;
        border-radius: unset;
        &::before {
            content: unset;
        }
        &.world-cup {
            .title {
                font-size: 6rem;
                line-height: 7.2rem;
            }
            .article {
                &-list {
                    align-items: flex-end;
                }
                &-item {
                    &:first-child {
                        height: 23rem;
                        .article-thumbnail {
                            height: 13rem;
                            img {
                                width: 11.4rem;
                                height: 11.4rem;
                            }
                        }
                    }
                    &:nth-child(2) {
                        height: 25rem;
                        .article-thumbnail {
                            height: 15rem;
                            img {
                                width: 13.4rem;
                                height: 13.4rem;
                            }
                        }
                    }
                    &:nth-child(3) {
                        height: 23rem;
                        .article-thumbnail {
                            height: 13rem;
                            img {
                                width: 9.6rem;
                                height: 9.6rem;
                            }
                        }
                        .name {
                            font-size: 1.6rem;
                        }
                    }
                }
                &-thumbnail {
                    &::after {
                        height: 50%;
                    }
                    .img-box {
                        align-items: flex-end;
                        filter: drop-shadow(0 -1rem 5rem hsl(var(--hsl-c-blue-light) / .6));
                    }
                    img {
                        width: 15rem;
                        height: 15rem;
                        margin-bottom: var(--full-space);
                    }
                }
                &-wrap {
                    width: 100%;
                    display: flex;
                    gap: 0;
                }
                &-content {
                    min-height: 10rem;
                    padding-top: 2rem;
                    padding-left: 0;
                }
                &-content-wrap:not(:first-child) {
                    margin-top: 0;
                }
            }
            .two-position {
                .article-thumbnail {
                    flex-direction: row;
                    > * {
                        flex-basis: 50%;
                    }
                }
            }
        }
        .waf-body {
            padding-bottom: 9.5rem;
        }
        .layout-wrapper {
            padding-inline: 0;
            border-radius: var(--full-radius);
        }
        .list-item {
            padding-inline: var(--one-n-half-space);
        }
        .two-position {
            .article-thumbnail {
                :nth-child(2) {
                    margin-left: unset;
                }
            }
            .article-content {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
        .name {
            line-height: 1.2;
        }
        .first-name {
            font-size: 1.8rem;
        }
        .last-name {
            font-size: 2rem;
            word-break: break-word;
        }
        .waf-select-box:last-child::before {
            right: -2.5rem;
            left: unset;
        }
        .dropdown-wrap {
            flex-direction: row-reverse;
        }
        .article-name {
            text-align: center;
        }
        .swiper {
            &-pagination {
                margin-top: 8.5rem;
                &-bullet {
                    cursor: pointer;
                }
            }
            &-button {
                width: unset;
                display: block;
                &::after {
                    color: hsl(var(--hsl-c-white) / .4);
                    font-size: 4rem;
                }
                &:hover::after {
                    color: var(--c-white);
                }
            }
        }
        .article-thumbnail {
            img {
                object-fit: contain;
                object-position: bottom;
            }
        }
        .waf-footer {
            position: static;
        }
    }
    .homepage .waf-podium .tabs-section {
        width: max-content;
    }
}
@media screen and (min-width:$desktop-min-width) {
    .waf-podium,
    .waf-standings .featured-item {
        .logo-wrap {
            width: 5.2rem;
        }
        .tabs .tab-text {
            font-size: 1.6rem;
        }
        .title {
            margin-bottom: var(--one-n-half-space);
            font-size: 4.2rem;
            line-height: 5.2rem;
        }
        .selected-label {
            font-size: 2.4rem;
        }
        .waf-select-box:last-child::before {
            width: .3rem;
            height: 4.2rem;
        }
        .article,
        .card {
            &-list {
                width: 85%;
                column-gap: var(--two-space);
            }
            &-item {
                &:first-child {
                    height: 33rem;
                    .article-thumbnail,
                    .card-thumbnail {
                        height: 20rem;
                    }
                }
                &:last-child {
                    height: 27rem;
                    .article-thumbnail,
                    .card-thumbnail {
                        height: 14rem;
                    }
                }
            }
        }
    }
    .waf-podium {
        padding-block: 7rem;
        .layout-wrapper {
            padding-block: 4.4rem 3.5rem;
        }
        &.world-cup {
            .article-item {
                &:first-child {
                    height: 25rem;
                    .article-thumbnail {
                        height: 15rem;
                        img {
                            width: 12.5rem;
                            height: 12.5rem;
                        }
                    }
                    .name {
                        font-size: 2rem;
                    }
                }
                &:nth-child(2) {
                    height: 27rem;
                    .article-thumbnail {
                        height: 17rem;
                        img {
                            width: 15rem;
                            height: 15rem;
                        }
                    }
                    .name {
                        font-size: 2.4rem;
                    }
                }
            }
        }
    }
    .waf-standings {
        .detail-container-wrapper .featured-item {
            height: auto;
        }
        .featured-item {
            padding-bottom: 8rem;
            .meta-title {
                padding-block: 4.6rem 5.4rem;
                .meta {
                    font-size: 2.4rem;
                    font-weight: 700;
                }
            }
            .card {
                &-item {
                    padding: 0;
                    &:first-child {
                        height: 31rem;
                        .image {
                            filter: drop-shadow(0 -5rem 5rem hsl(var(--hsl-c-white) / .7));
                        }
                        .fname {
                            font-size: 1.6rem;
                        }
                        .lname {
                            font-size: 1.8rem;
                        }
                    }
                    &:nth-child(2) {
                        height: 38.8rem;
                        .card-content {
                            min-height: 13rem;
                            padding-block: 2rem;
                        }
                        .card-thumbnail {
                            height: 26rem;
                        }
                        .image {
                            filter: drop-shadow(0 -7rem 7rem hsl(var(--hsl-c-white) / .7));
                        }
                    }
                    &:nth-child(3) {
                        height: 28.6rem;
                        .lname {
                            font-size: 1.6rem;
                        }
                        .card-thumbnail {
                            height: 17.6rem;
                        }
                    }
                }
                &-content {
                    min-height: 11rem;
                    padding: var(--full-space);
                    align-items: unset;
                    gap: 0 var(--half-space);
                    grid-template-areas: 'a c'
                        'b d';
                    grid-template-columns: calc(100% - var(--half-space) - 7rem) 7rem;
                }
                &-number {
                    right: var(--full-space);
                    left: unset;
                }
                &-name {
                    align-items: flex-start;
                    justify-content: flex-start;
                }
            }
        }
    }
}